import styled from 'styled-components';
import colors from '../../assets/colors';

export const Wrapper = styled.div`
  height: 100vh;
  overflow: hidden;
  color: ${colors.light};
  display: grid;
  grid-template-rows: 1fr auto;
`;

export const DarkBackground = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 90vh;
  width: 100%;
  background-color: ${colors.dark};
  color: ${colors.light};
`;

export const PortraitWrapper = styled.div`
  margin-left: auto;
  margin-right: 20%;
  margin-bottom: 20px;
`;

export const ProfilePicture = styled.div`
  content: '';
  background: url('/images/pala_with_border.png') no-repeat center;
  background-size: contain;
  height: 350px;
  width: 400px;

  // small device
  @media (max-width: 450px) {
    height: 300px;
  }
`;
