import React from 'react';
import { useTranslation } from 'react-i18next';

import colors from '../../assets/colors';
import * as AS from '../../App.styles';
import {
  TopColumn,
  LeftBottomColumn,
  RightBottomColumn,
  Footer,
} from './components';
import { printComponent } from '../../utils/printComponent';
import { downloadComponentPdf } from '../../utils/downloadComponentPdf';
import * as S from './CV.styles';
import { Button } from '../../components';

const CVPage = () => {
  const { t } = useTranslation();

  const PrintPageComponent = () => (
    <S.PrintPageWrapper id="printable-page">
      <S.PrintPage>
        <TopColumn />
        <S.Columns>
          <LeftBottomColumn />
          <RightBottomColumn />
        </S.Columns>
        <Footer />
      </S.PrintPage>
    </S.PrintPageWrapper>
  );

  return (
    <S.Wrapper>
      <AS.Content>
        <S.TopSection>
          <AS.Title>{t('CVPage.CV')}</AS.Title>
          <S.ButtonWrapper>
            <Button
              text={t('CVPage.Print')}
              backgroundColor={colors.dark}
              textColor={colors.light}
              onClicked={() => printComponent('printable-page')}
            />
          </S.ButtonWrapper>
        </S.TopSection>
        <PrintPageComponent />
      </AS.Content>
    </S.Wrapper>
  );
};

export default CVPage;
