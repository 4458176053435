import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './Home.style';
import * as AS from '../../App.styles';

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <>
      <S.DarkBackground />
      <S.Wrapper>
        <AS.Content>
          <AS.Title>Pála Ögn</AS.Title>
        </AS.Content>
        <S.PortraitWrapper>
          <S.ProfilePicture />
        </S.PortraitWrapper>
      </S.Wrapper>
    </>
  );
};

export default HomePage;
