import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import colors from '../../assets/colors';
import * as AS from '../../App.styles';

const Wrapper = styled.div`
  height: 100vh;
  background-color: ${colors.dark};
  color: ${colors.light};
`;

const ProjectsPage = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <AS.Content>
        <AS.Title>{t('ProjectsPage.Title')}</AS.Title>
      </AS.Content>
    </Wrapper>
  );
};

export default ProjectsPage;
