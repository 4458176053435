import styled from 'styled-components';
import colors from '../../../assets/colors';

export const Container = styled.div`
  padding: 10% 10% 0 0;
`;

export const SubTitle = styled.button<{ textColor?: string }>`
  border: 3px dashed transparent;
  background: none;
  width: auto;
  font-size: 1em;
  color: ${({ textColor }) => textColor || colors.light};
  font-weight: 200;
  text-transform: uppercase;
  padding-top: 15px;
  letter-spacing: 1px;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
    border: 3px dashed ${colors.light};
  }

  &:focus {
    outline: none !important;
    border: 3px dashed ${colors.light};
  }
`;
