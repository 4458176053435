export const LeftColumnSectionsData = [
  /* {
    title: 'Personal skills',
    text: 'I love to learn new things everyday, Positive person, Good work ethics, ',
  }, */
  {
    title: 'Interests',
    text: 'Ashtanga Yoga, Snowboarding, Coffee, Beer Brewing, Sourdough, Salsa dancing, Exotic Cultures, Plant based food',
  },
];

export const RightColumnSectionsData = [
  {
    title: 'Personal Profile',
    text: 'I’m a software developer with passion for front end web development. I love working on new and challenging projects. Im a positive person that tries to make the world better every day one step at a time!',
  },
  {
    title: 'Career Summary',
    subSections: [
      {
        title: 'Software Developer',
        subTitle: 'K3 Business Technologies',
        time: 'From January 2019',
      },
      {
        title: 'Developer, Summer intern',
        subTitle: 'Aranja',
        time: 'Summer 2016',
      },
      {
        title: 'NPM',
        subTitle: 'Personal Assistant',
        time: 'January 2016 to 2019, with school',
      },
      {
        title: 'Labour work in Pot Room',
        subTitle: 'Rio Tinto Alcan',
        time: '2012-2016, with school',
      },
    ],
  },
  {
    title: 'Education',
    subSections: [
      {
        title: 'Bsc. Computer Science',
        subTitle: 'Reykjavík University',
        time: '2015-2018',
        subSections: [
          {
            title: 'Erasmus exchange studies',
            subTitle: 'Brno University of Technology',
            time: 'winter 2017 - summer 2018',
          },
        ],
      },
      {
        title: 'Global Business',
        subTitle: 'Commercial College of Iceland',
        time: '2010 - 2014',
      },
    ],
  },
];
