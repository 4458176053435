import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import colors from '../../../assets/colors';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 20px;
  padding-bottom: 30px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  @media print {
    grid-template-columns: auto 1fr;
  }
`;

const ProfileImage = styled.div`
  height: 150px;
  width: 150px;
  margin: 0 20px;
  border-radius: 50%;
  border: 4px solid ${colors.dark};
  box-sizing: border-box;
  filter: drop-shadow(8px 8px 0px #ffe878);

  background: url('/images/pala_ogn_portrait.jpg') no-repeat center;
  background-size: cover;

  // small device
  @media (max-width: 600px) {
    height: 100px;
    width: 100px;
  }

  @media print {
    height: 150px;
    width: 150px;
  }
`;

const Title = styled.div`
  font-size: 26px;
  background-color: ${colors.dark};
  color: black;
  padding: 10px;
`;

const SubTitle = styled.div`
  font-size: 18px;
  padding-top: 5px;
`;

const Text = styled.div`
  font-size: 16px;
  color: black;
`;

const TopColumn = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ProfileImage />
      <div>
        <Title>Pála Ögn Stefánsdóttir</Title>
        <SubTitle>{t('CVPage.Title')}</SubTitle>
        <Text>{t('CVPage.SubTitle')}</Text>
      </div>
    </Wrapper>
  );
};

export default TopColumn;
