import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import colors from '../../../assets/colors';
import { getRightColumnSectionsData } from '../data';

const Wrapper = styled.div`
  height: 100%;
  padding-left: 10px;
`;
const Title = styled.div`
  font-size: 20px;
  background-color: ${colors.dark};
  color: black;
  padding: 6px;
`;

const SubTitle = styled.div`
  font-size: 16px;
`;

const Text = styled.div`
  font-size: 14px;
  color: black;
`;

const Section = styled.div`
  margin: auto;
`;

const Row = styled.div`
  padding: 5px 10px;
`;

const SubRow = styled.div`
  margin-left: 10px;
  padding: 5px 10px;
`;

const RightBottomColumn = () => {
  const { t } = useTranslation();
  const RightColumnSectionsData = getRightColumnSectionsData();

  const renderSubRow = ({
    title,
    subTitle,
    time,
  }: {
    title: string;
    subTitle: string;
    time: string;
  }) => {
    return (
      <SubRow key={title}>
        <SubTitle>{title}</SubTitle>
        <Text>{subTitle}</Text>
        <Text>{time}</Text>
      </SubRow>
    );
  };

  const renderRow = ({
    title,
    subTitle,
    time,
    subSections,
  }: {
    title: string;
    subTitle: string;
    time: string;
    subSections: any[];
  }) => {
    return (
      <Row key={title}>
        <SubTitle>{title}</SubTitle>
        <Text>{subTitle}</Text>
        <Text>{time}</Text>
        {subSections && subSections.map((s: any) => renderSubRow(s))}
      </Row>
    );
  };

  const renderSection = ({
    title,
    text,
    subSections,
  }: {
    title: string;
    text: string;
    subSections: any[];
  }) => {
    return (
      <Section key={title}>
        <Title>{title}:</Title>
        {text && (
          <Row>
            <Text>{text} </Text>
          </Row>
        )}
        {subSections && subSections.map((s: any) => renderRow(s))}
      </Section>
    );
  };

  return (
    <Wrapper>
      {RightColumnSectionsData.map((s: any) => renderSection(s))}
    </Wrapper>
  );
};

export default RightBottomColumn;
