import styled from 'styled-components';

import colors from '../../assets/colors';

export const Wrapper = styled.div`
  min-height: 100vh;
  background-color: ${colors.light};
  color: ${colors.dark};
  margin-bottom: 60px;
`;

export const TopSection = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  margin-top: 10vh;
`;

export const ButtonWrapper = styled.div`
  margin: auto 0 10px 0;

  * {
    margin-left: 20px;
  }
`;

export const PrintPage = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  position: relative;
  height: 100%;
  max-width: 2200px;
  max-height: 3508px;
  padding: 20px 20px 0 10px;
  color: black;

  border: 2px solid ${colors.dark};

  @media print {
    padding: 40px;
    height: 90vh;
    width: 88vw;
    -webkit-print-color-adjust: exact;
  }
`;

export const PrintPageWrapper = styled.div`
  @media print {
    padding: 40px;
    margin: 20px;
  }
`;

export const Columns = styled.div`
  display: grid;
  grid-template-columns: 4fr 6fr;
  grid-column-gap: 20px;
  height: 100%;

  // small device
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  @media print {
    grid-template-columns: 1fr 2fr;
  }
`;
