import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import * as AS from '../../App.styles';
import colors from '../../assets/colors';

const Wrapper = styled.div`
  // height: 90vh;
  background-color: ${colors.light};
  color: ${colors.dark};
  margin-bottom: 10vh;
`;

const TextWrapper = styled.div`
  margin: 10% auto 0 auto;
  padding: 20px;
  max-width: 700px;
  font-size: 0.9em;
  white-space: pre-wrap;
`;

function About() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <AS.Content>
        <TextWrapper>
          <AS.SubTitle>{t('AboutPage.Title')}</AS.SubTitle>
          <AS.Text>{t('AboutPage.AboutMe')}</AS.Text>
          <br></br>
          <AS.SubTitle>{t('AboutPage.InterestsTitle')} </AS.SubTitle>
          <AS.Text>{t('AboutPage.Interests')}</AS.Text>
        </TextWrapper>
      </AS.Content>
    </Wrapper>
  );
}

export default About;
