import i18n from '../../../assets/languages/i18n';
import * as en from './en';
import * as is from './is';

export const getLeftColumnSectionsData = (): any[] => {
  switch (i18n.language) {
    case 'is':
      return is.LeftColumnSectionsData;
    case 'en':
    default:
      return en.LeftColumnSectionsData;
  }
};

export const getRightColumnSectionsData = (): any[] => {
  switch (i18n.language) {
    case 'is':
      return is.RightColumnSectionsData;
    case 'en':
    default:
      return en.RightColumnSectionsData;
  }
};
