import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import colors from '../../../assets/colors';
import { getLeftColumnSectionsData } from '../data';

const Wrapper = styled.div`
  height: 100%;
  padding-right: 5px;
  padding-left: 10px;

  // small device
  @media (max-width: 600px) {
    border: none;
  }
`;

const Title = styled.div`
  font-size: 18px;
  padding: 5px 0;
`;

const SubTitle = styled.div`
  font-size: 16px;
  padding: 5px 0;
`;

const Text = styled.div`
  font-size: 14px;
  color: black;
  padding-left: 10px;
`;

const Line = styled.div`
  border-top: 2px solid ${colors.dark};
  margin: 10px 0;
  width: 80%;
`;

const Section = styled.div`
  margin: 10px 0;
`;

const LeftBottomColumn = () => {
  const { t } = useTranslation();
  const LeftColumnSectionData = getLeftColumnSectionsData();

  const renderSection = ({ title, text }: { title: string; text: string }) => {
    return (
      <Section key={title}>
        <Line />
        <SubTitle>{title}:</SubTitle>
        <Text>{text}</Text>
      </Section>
    );
  };

  return (
    <Wrapper>
      <Text>{t('CVPage.Location')}</Text>
      <Text>{t('CVPage.Email')}</Text>
      {LeftColumnSectionData.map((section: any) => renderSection(section))}
    </Wrapper>
  );
};

export default LeftBottomColumn;
