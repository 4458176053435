import React from 'react';
import styled from 'styled-components';

import { LanguageOption } from '../../types';
import colors from '../../assets/colors';

const Container = styled.div<{ textColor?: string }>`
  color: ${(props) => props.textColor || colors.light};
`;

const SubTitle = styled.button<{ active: boolean }>`
  border: none;
  background: none;
  border-bottom: 3px solid transparent;
  color: inherit;
  display: inline-block;
  font-size: 1.3em;
  font-weight: 200;
  letter-spacing: 1px;
  cursor: pointer;

  text-decoration: ${(props) => (props.active ? 'overline' : '')};

  &:hover {
    transform: scale(1.2);
  }

  &:focus {
    outline: none !important;
    border-bottom: 3px dashed ${colors.light};
  }
`;

const Divider = styled.span`
  padding: 0 0.5em;
  font-size: 1.5em;
  font-weight: 600;
`;

const isNotLast = (index: number, length: number) => index + 1 < length;

const LanguagePickerComponent = ({
  languageOptions,
  onLanguageClicked,
  selectedLanguage,
  textColor,
}: {
  languageOptions: LanguageOption[];
  selectedLanguage?: string;
  textColor?: string;
  onLanguageClicked: Function;
}) => {
  return (
    <Container textColor={textColor}>
      {languageOptions.map((l, index) => {
        return (
          <span key={l.languageCode}>
            <SubTitle
              active={l.languageCode === selectedLanguage}
              onClick={() => onLanguageClicked(l)}
            >
              {l.languageName}
            </SubTitle>
            {isNotLast(index, languageOptions.length) ? (
              <Divider>/</Divider>
            ) : null}
          </span>
        );
      })}
    </Container>
  );
};

export default LanguagePickerComponent;
