import React from 'react';
import { Link } from 'react-scroll';

import * as S from './VerticalOneLetterNav.style';
import { NavOption } from '../../../types';

const VerticalOneLetterNavComponent = ({
  navOptions,
  textColor,
}: {
  navOptions: NavOption[];
  textColor?: string;
}) => {
  return (
    <S.Container>
      {navOptions.map((o) => {
        return (
          <Link to={o.to} spy={true} smooth={true} duration={500} key={o.to}>
            <S.SubTitle textColor={textColor}>{o.title}</S.SubTitle>
          </Link>
        );
      })}
    </S.Container>
  );
};

export default VerticalOneLetterNavComponent;
