import React from 'react';
import styled from 'styled-components';

import colors from '../../assets/colors';

export const Button = styled.button<{
  backgroundColor?: string;
  textColor?: string;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor || colors.dark};
  color: ${({ textColor }) => textColor || colors.light};
  border: none;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.2);
  }
`;

const ButtonComponent = ({
  backgroundColor,
  textColor,
  text,
  onClicked = () => {},
}: {
  backgroundColor?: string;
  textColor?: string;
  text: string;
  onClicked?: Function;
}) => {
  return (
    <Button
      background-color={backgroundColor}
      textColor={textColor}
      onClick={() => onClicked()}
    >
      {text}
    </Button>
  );
};

export default ButtonComponent;
