import React, { useState } from 'react';
import { Element } from 'react-scroll';
import { useTranslation } from 'react-i18next';

import * as S from './App.styles';
import i18n, { languageOptions } from './assets/languages/i18n';
import { LandingPage, AboutPage, ProjectsPage, CVPage } from './pages';
import { LanguageOption } from './types';
import { useWindowWidth } from './hooks/useWindowWidth';
import { useScrollPosition } from './hooks/useScrollPosition';
import {
  LanguagePicker,
  VerticalOneLetterNavComponent,
  VerticalSentenceNavComponent,
  HorizontalNavComponent,
} from './components';

const App = () => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    i18n.language
  );
  const windowWidth = useWindowWidth();
  const scrollPosition = useScrollPosition();

  const { t } = useTranslation();
  const navOptions = [
    { to: 'landing-page', title: t('Pages.LandingPage') },
    //   { to: 'about-page', title: t('Pages.About') },
    //  { to: 'projects-page', title: t('Pages.Projects') },
    { to: 'cv-page', title: t('Pages.Cv') },
  ];

  const changeLanguage = (lng: LanguageOption) => {
    setSelectedLanguage(lng.languageCode);
    i18n.changeLanguage(lng.languageCode);
  };

  const renderNav = () => {
    // small device
    if (windowWidth && windowWidth < 450) {
      return (
        <S.HorizontalNavContainer>
          <HorizontalNavComponent navOptions={navOptions} />
        </S.HorizontalNavContainer>
      );
    }

    if (!scrollPosition || scrollPosition <= 30) {
      return (
        <S.NavContainer>
          <VerticalSentenceNavComponent navOptions={navOptions} />
        </S.NavContainer>
      );
    }

    return (
      <S.RightNavContainer>
        <VerticalOneLetterNavComponent navOptions={navOptions} />
      </S.RightNavContainer>
    );
  };

  /*
        <Element name={navOptions[1].to}>
          <AboutPage />
        </Element>
        <Element name={navOptions[2].to}>
          <ProjectsPage />
        </Element> */
  const renderPages = () => {
    return (
      <>
        <Element name={navOptions[0].to}>
          <LandingPage />
        </Element>

        <Element name={navOptions[1].to}>
          <CVPage />
        </Element>
      </>
    );
  };

  /*
   <S.LanguagePickerContainer>
          <LanguagePicker
            languageOptions={languageOptions}
            selectedLanguage={selectedLanguage}
            onLanguageClicked={changeLanguage}
          />
   </S.LanguagePickerContainer>
  */

  return (
    <>
      <S.GlobalStyle />
      <S.TopBar>{renderNav()}</S.TopBar>

      {renderPages()}
    </>
  );
};

export default App;
