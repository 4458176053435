import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 10px;
  text-align: center;
`;

const Text = styled.div`
  position: relative;
  display: inline-block;
  color: grey;
  font-size: 12px;
  height: 20px;
  line-height: 20px;

  &:before {
    position: absolute;
    left: -25px;
    content: '';
    height: 20px;
    width: 20px;
    background: url('/images/flower.svg') no-repeat center;
    background-size: cover;
  }
`;

const Footer = () => {
  return (
    <Wrapper>
      <Text>www.palaogn.is</Text>
    </Wrapper>
  );
};

export default Footer;
