export const LeftColumnSectionsData = [
  {
    title: 'Styrkleikar',
    text:
      'I’m a software developer with passion for front end web development. I love working on new and challenging projects. Im a positive person that tries to make the world better every day!',
  },
  {
    title: 'Áhugamál',
    text:
      'Ashtanga Yoga, Snjóbretti, Kaffi, Bjórbrugg, Súrdeig, Salsa dans, Jurtafæði',
  },
];

export const RightColumnSectionsData = [
  {
    title: 'Hver er ég?',
    text:
      'I’m a software developer with passion for front end web development. I love working on new and challenging projects. Im a positive person that tries to make the world better every day!',
  },
  {
    title: 'Starfsferill',
    subSections: [
      {
        title: 'Forritari',
        subTitle: 'K3 Business Technologies',
        time: 'Frá Janúar 2019',
      },
      {
        title: 'Forritari',
        subTitle: 'Aranja',
        time: 'Sumar 2016',
      },
      {
        title: 'NPM',
        subTitle: 'Persónulegur Aðstoðarmaður',
        time: 'Janúar 2016 til 2019, með skóla',
      },
      {
        title: 'Rafgreinir í kerskála',
        subTitle: 'Rio Tinto Alcan',
        time: '2012-2016, with school',
      },
    ],
  },
  {
    title: 'Menntun',
    subSections: [
      {
        title: 'Bsc. Tölvunarfræði',
        subTitle: 'Háskólinn í Reykjavík',
        time: '2015-2018',
        subSections: [
          {
            title: 'Erasmus skiptinám',
            subTitle: 'Brno University of Technology',
            time: 'vetur 2017 - sumar 2018',
          },
        ],
      },
      {
        title: 'Alþjóðafræði',
        subTitle: 'Verzlunarskóli Íslands',
        time: '2010 - 2014',
      },
    ],
  },
];
