import styled, { createGlobalStyle, keyframes } from 'styled-components';
import colors from './assets/colors';

// https://fonts.googleapis.com/css?family=Tenor+Sans
export const GlobalStyle = createGlobalStyle`
@import url(https://fonts.googleapis.com/css?family=Helvetica);

* {
  font-family: 'Helvetica';
  letter-spacing: 1px;
  font-size: 100%;
  margin: 0;
 

   // small device
   @media (max-width: 600px) {
    font-size: 97%;
  }
}
`;

const fadeIn = keyframes`
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
`;

export const Content = styled.div`
  max-width: 800px;
  margin: auto;
  padding: 0 10%;
  background-color: inherit;
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 4em;
  letter-spacing: 5px;
  animation: ${fadeIn} ease 2s;
  background-color: inherit;

  // small device
  @media (max-width: 450px) {
    padding-top: 10vh;
  }
`;

export const SubTitle = styled.h2`
  margin: 0;
  font-size: 3em;
  font-weight: 200;
  letter-spacing: 5px;
`;

export const Text = styled.div`
  font-size: 1.5em;
  font-weight: 200;
  letter-spacing: 5px;
`;

export const TopBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  width: 100%;
  // background-color: ${colors.dark};
`;

export const LanguagePickerContainer = styled.div`
  position: fixed;
  left: 30px;
  top: 20px;
`;

export const NavContainer = styled.div`
  position: fixed;
  right: 20px;
  top: 30px;
  margin-left: 40px;
  animation: ${fadeIn} ease 0.5s;

  // small device
  @media (max-width: 450px) {
    top: 30px;
  }
`;

export const HorizontalNavContainer = styled.div`
  position: fixed;
  right: 5px;
  top: 50px;
  margin-left: 40px;
  animation: ${fadeIn} ease 0.5s;

  // small device
  @media (max-width: 450px) {
    top: 30px;
  }
`;

export const RightNavContainer = styled.div`
  mix-blend-mode: color-dodge;
  position: fixed;
  right: 20px;
  top: 35px;
  margin-left: 40px;
  color: ${colors.dark};
  animation: ${fadeIn} ease 0.5s;

  // small device
  @media (max-width: 450px) {
    top: 30px;
  }
`;

export const MixinText = styled.div`
  mix-blend-mode: color-dodge;
  position: fixed;
  top: 80px;
  right: 80px;
  font-size: 40px;
  color: ${colors.dark};
  z-index: 3;
`;
