import styled from 'styled-components';

const PrintPageWrapper = styled.div`
  width: 2480px;
  padding: 40px;
  margin: 20px;
`;

export const printComponent = (componentId: string) => {
  if (document && document.getElementById && document.body) {
    const printableElements = document.getElementById(componentId)?.innerHTML;
    const printableElementPage = `<PrintPageWrapper>${printableElements}</PrintPageWrapper>`;
    const oldPage = document.body.innerHTML;
    document.body.innerHTML = printableElementPage;

    window.print();
    document.body.innerHTML = oldPage;
  }
};
