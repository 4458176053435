import styled from 'styled-components';
import colors from '../../../assets/colors';

export const Container = styled.div`
  width: 100%;
`;

export const SubTitle = styled.button<{ textColor?: string }>`
  border: none;
  border-bottom: 3px solid transparent;
  background: none;
  display: inline-block;
  font-size: 1.5em;
  color: ${({ textColor }) => textColor || colors.light};
  font-weight: 200;
  text-transform: uppercase;
  padding: 10px 0;
  margin-right: 20px;
  letter-spacing: 1px;
  cursor: pointer;

  transition: transform 0.3s ease;
  transform: translateY(0px);

  &:hover {
    transform: translateY(-5px) scale(1.1);
    border-bottom: 3px dashed ${colors.light};
  }

  &:focus {
    outline: none !important;
    border-bottom: 3px dashed ${colors.light};
  }
`;
