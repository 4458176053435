import styled from 'styled-components';
import colors from '../../../assets/colors';

export const Container = styled.div`
  text-align: center;
  word-break: break-all;
`;

export const SubTitle = styled.button<{ textColor?: string }>`
  border: none;
  border-left: 3px solid transparent;
  background: none;
  display: inline-block;
  margin: 10px;
  background: none;
  width: 10px;
  font-size: 1.2em;
  color: ${({ textColor }) => textColor || colors.light};
  font-weight: 200;
  text-transform: uppercase;
  text-align: center;
  padding: 15px 10px;
  letter-spacing: 1px;
  cursor: pointer;

  transition: transform 0.3s ease;
  transform: translateY(0px);

  &:hover {
    transform: translateY(15px) scale(1.2);
  }

  &:focus {
    outline: none !important;
    border-left: 3px dashed ${colors.light};
    border-bottom: 3px dashed ${colors.light};
    transform: translateY(5px);
  }

  :after {
    white-space: pre;
  }
`;
